<template>
  <div style="background-color:#f5f5f5">
    <div class="data-preview">
      <div class="data-preview_title"><div class="title_before"></div>数据总览</div>
      <div class="data-preview_container">
        <div v-for="(item, index) in previewList" :key="index" class="data-preview_item flex-item" :style="{'margin-right':(index+1)%4==0?0:'11px'}">
          <div class="data-preview_value">{{item.value}}</div>
          <div class="data-preview_name">{{item.name}}</div>
        </div>
      </div>
      <template v-if="resVisible">
        <div class="divider"></div>
        <div class="data-preview_info">
          <div class="info_top">
            <img :src="require('@/assets/img/icon/res-icon.png')" alt="" width="42.5">
            <div class="flex-item" style="margin-left:10px;align-items:flex-start">
              <div class="top_num">{{resCount}}</div>
              <div class="top_name">人口总数</div>
            </div>
          </div>
          <div class="info_bottom">
            <template v-for="(item, index) in resInfo">
              <div class="flex-item bottom_item" :key="index">
                <div class="bottom_num blue">{{item.count}}</div>
                <div class="bottom_name">{{item.label}}</div>
              </div>
              <div class="res_divider" v-if="index !== resInfo.length -1"></div>
            </template>
<!--            <div class="flex-item bottom_item">-->
<!--              <div class="bottom_num blue">{{resInfo.residentCount}}<span class="bottom_present">({{resInfo.count ? (resInfo.residentCount/resInfo.count*100).toFixed(0) : 0}}%)</span></div>-->
<!--              <div class="bottom_name">常住人口</div>-->
<!--            </div>-->
<!--            <div class="data_divider"></div>-->
<!--            <div class="flex-item bottom_item">-->
<!--              <div class="bottom_num green">{{resInfo.transientCount}}<span class="bottom_present">({{resInfo.count ? (resInfo.transientCount/resInfo.count*100).toFixed(0) : 0}}%)</span></div>-->
<!--              <div class="bottom_name">流动人口</div>-->
<!--            </div>-->
<!--            <div class="data_divider"></div>-->
<!--            <div class="flex-item bottom_item">-->
<!--              <div class="bottom_num orange">{{resInfo.otherCount}}<span class="bottom_present">({{resInfo.count ? (resInfo.otherCount/resInfo.count*100).toFixed(0) : 0}}%)</span></div>-->
<!--              <div class="bottom_name">其他人口</div>-->
<!--            </div>-->
          </div>
        </div>
      </template>

      <template v-if="houseVisible">
        <div class="divider"></div>
        <div class="data-preview_info">
          <div class="info_top">
            <img :src="require('@/assets/img/icon/house-icon.png')" alt="" width="42.5">
            <div class="flex-item" style="margin-left:10px;align-items:flex-start">
              <div class="top_num">{{houseInfo.count}}</div>
              <div class="top_name">房屋总数</div>
            </div>
          </div>
          <div class="info_bottom">
            <div class="flex-item bottom_item">
              <div class="bottom_num blue">{{houseInfo.selfResideCount}}<span class="bottom_present" v-if="houseInfo.selfResideCount !== 0">({{(houseInfo.selfResideCount/houseInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">自住</div>
            </div>
            <div class="data_divider"></div>
            <div class="flex-item bottom_item">
              <div class="bottom_num green">{{houseInfo.rentOutCount}}<span class="bottom_present" v-if="houseInfo.rentOutCount !== 0">({{(houseInfo.rentOutCount/houseInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">出租</div>
            </div>
            <div class="data_divider"></div>
            <div class="flex-item bottom_item">
              <div class="bottom_num orange">{{houseInfo.otherCount}}<span class="bottom_present" v-if="houseInfo.otherCount !== 0">({{(houseInfo.otherCount/houseInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">其他</div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="placeVisible">
        <div class="divider"></div>
        <div class="data-preview_info">
          <div class="info_top">
            <img :src="require('@/assets/img/icon/place-icon.png')" alt="" width="42.5">
            <div class="flex-item" style="margin-left:10px;align-items:flex-start">
              <div class="top_num">{{placeInfo.count}}</div>
              <div class="top_name">总数</div>
            </div>
          </div>
          <div class="info_bottom">
            <div class="flex-item bottom_item">
              <div class="bottom_num blue">{{placeInfo.placeCount}}<span class="bottom_present" v-if="placeInfo.placeCount !== 0">({{(placeInfo.placeCount/placeInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">场所</div>
            </div>
            <div class="data_divider"></div>
            <div class="flex-item bottom_item">
              <div class="bottom_num green">{{placeInfo.gtgshCount}}<span class="bottom_present" v-if="placeInfo.gtgshCount !== 0">({{(placeInfo.gtgshCount/placeInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">沿街店铺</div>
            </div>
            <div class="data_divider"></div>
            <div class="flex-item bottom_item">
              <div class="bottom_num orange">{{placeInfo.qyCount}}<span class="bottom_present" v-if="placeInfo.qyCount !== 0">({{(placeInfo.qyCount/placeInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">企业</div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="deviceVisible">
        <div class="divider"></div>
        <div class="data-preview_info">
          <div class="info_top">
            <img :src="require('@/assets/img/icon/device-icon.png')" alt="" width="42.5">
            <div class="flex-item" style="margin-left:10px;align-items:flex-start">
              <div class="top_num">{{deviceInfo.count}}</div>
              <div class="top_name">总数</div>
            </div>
          </div>
          <div class="info_bottom">
            <div class="flex-item bottom_item">
              <div class="bottom_num blue">{{deviceInfo.deviceCount}}<span class="bottom_present" v-if="deviceInfo.deviceCount !== 0">({{(deviceInfo.deviceCount/deviceInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">智能设备</div>
            </div>
            <div class="data_divider"></div>
            <div class="flex-item bottom_item">
              <div class="bottom_num green">{{deviceInfo.carCount}}<span class="bottom_present" v-if="deviceInfo.carCount !== 0">({{(deviceInfo.carCount/deviceInfo.count*100).toFixed(0)}}%)</span></div>
              <div class="bottom_name">车辆信息</div>
            </div>
          </div>

        </div>
      </template>

    </div>

    <!-- <div class="data-preview">
      <div class="data-preview_title"><div class="title_before"></div>网格管理</div>
      <div class="data-preview_container">
        <div v-for="(item, index) in gridList" :key="index" class="data-preview_item item_grid flex-item" :style="{'margin-right':(index+1)%3==0?0:'22.5px'}">
          <div class="data-preview_value">{{item.value}}</div>
          <div class="data-preview_name">{{item.name}}</div>
        </div>
      </div>
    </div>

    <div class="data-preview">
      <div class="data-preview_title"><div class="title_before"></div>查览社区</div>
      <div class="data-preview_container">
        <div v-for="(item, index) in communityList" :key="index" class="item_community" :style="{'margin-right':(index+1)%3==0?0:'16px'}">
          <div class="community_name">{{item.name}}</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      previewList: [],
      resInfo: [],
      resVisible: false,
      houseInfo: null,
      houseVisible: false,
      placeInfo: null,
      placeVisible: false,
      deviceInfo: null,
      deviceVisible: false,
      gridList: [],
      communityList: [],
      resCount: null
    }
  },
  created () {
    this.getTopData()
    this.getResData()
    this.getHouseData()
    this.getPlaceData()
    this.getDeviceData()
  },
  methods: {
    getTopData () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/jdgg/overview/info/top'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.previewList = [{
            name: '社区', value: data.data.communityNum
          }, {
            name: '小区', value: data.data.subareaNum
          }, {
            name: '网格', value: data.data.gridNum
          }, {
            name: '楼幢', value: data.data.buildingNum
          }, {
            name: '房屋', value: data.data.houseNum
          }, {
            name: '社工', value: data.data.socialWorkerNum
          }, {
            name: '网格员', value: data.data.gridUserNum
          }]
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getResData () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/statistics/orgRegistry'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.resCount = data.list.filter(item => {
            return item.label == 'sum'
          })[0].count
          this.resVisible = true
          this.resInfo = data.list.filter(item => {
            return item.label !== 'sum'
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getHouseData () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/jdgg/overview/info/house'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.houseVisible = true
          this.houseInfo = data.data
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getPlaceData () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/jdgg/overview/info/place'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.placeVisible = true
          this.placeInfo = data.data
          this.placeInfo['placeCount'] = this.placeInfo.count - this.placeInfo.gtgshCount - this.placeInfo.qyCount
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDeviceData () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/jdgg/overview/info/device'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.deviceVisible = true
          this.deviceInfo = data.data
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.flex-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.data-preview {
  margin: 20px 30px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  .data-preview_title {
    font-weight: bold;
    font-size: 30px;
    color: #333;
    .title_before {
      width: 6px;
      height: 25px;
      border-radius: 2px;
      background-color: #266FF4;
      display: inline-block;
      margin-right: 10px;
    }
  }
  .data-preview_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .data-preview_item {
      background-color: #F2F7FF;
      width: 140px;
      height: 106px;
      margin: 0 20px 20px 0;
      border-radius: 10px;
      .data-preview_value {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
      .data-preview_name {
        margin-top: 2px;
        color: #999;
        font-size: 24px;
      }
    }
    .item_grid {
      width: 150px;
      margin-right: 45px;
    }
    .item_community {
      width: 188px;
      line-height: 60px;
      text-align: center;
      background-color: #F2F7FF;
      border-radius: 30px;
      margin-bottom: 20px;
      .community_name {
        color: #31363C;
        font-size: 26px;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #f5f5f5;
    margin: 30px 0;
  }
  .data-preview_info {
    .info_top {
      display: flex;
      .top_num {
        font-weight: bold;
        color: #333;
        font-size: 36px;
      }
      .top_name {
        margin-top: 4px;
        color: #666;
        font-size: 26px;
      }
    }
    .info_bottom {
      display: flex;
      align-items: center;
      font-size: 26px;
      margin-top: 30px;
      .bottom_item {
        width: 150px;
        .bottom_num {
          font-size: 26px;
          margin-top: 4px;
        }
        .bottom_name {
          font-size: 26px;
          margin-top: 4px;
        }
        .bottom_present {
          font-size: 24px;
        }
        .blue {
          color: #387FF5;
        }
        .green {
          color: #23D87B;
        }
        .orange {
          color: #FFA93A;
        }
      }
      .res_divider {
        width: 1px;
        height: 30px;
        background-color: #e5e5e5;
        margin: 0 20px;
      }
      .data_divider {
        width: 1px;
        height: 30px;
        background-color: #e5e5e5;
        margin: 0 40px;
      }
    }
  }
}
</style>
